import React from "react";

import { IStackProps, mergeStyleSets, Stack, useTheme } from "@bps/fluent-ui";

interface SideNavLayoutProps {
  nav: React.ReactNode;
  bodyProps?: IStackProps;
}

export const SideNavLayout: React.FC<SideNavLayoutProps> = ({
  nav,
  children,
  bodyProps
}) => {
  const theme = useTheme();

  const stackTokens = {
    childrenGap: theme.spacing.s1
  };

  return (
    <Stack horizontal verticalFill tokens={{ childrenGap: theme.spacing.s1 }}>
      <Stack
        verticalFill
        disableShrink
        tokens={stackTokens}
        styles={{
          root: {
            backgroundColor: theme.semanticColors.bodyBackground,
            padding: theme.spacing.s1,
            width: 230,
            overflowY: "auto"
          }
        }}
      >
        {nav}
      </Stack>

      <Stack
        verticalFill
        tokens={stackTokens}
        {...bodyProps}
        styles={mergeStyleSets(
          {
            root: {
              backgroundColor: theme.semanticColors.bodyBackground,
              padding: theme.spacing.s1,
              flex: "1",
              overflowY: "auto",
              overflowX: "hidden"
            }
          },
          bodyProps?.styles
        )}
      >
        {children}
      </Stack>
    </Stack>
  );
};
