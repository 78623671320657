import { EnvName } from "@components/EnvName";
import { UserProfile } from "./UserProfile";
import { useNavigate } from "react-router-dom";

import {
  ActionButton,
  Header,
  IButtonProps,
  IButtonStyles,
  IStyle,
  ITheme,
  useTheme
} from "@bps/fluent-ui";

import { ItemData } from "./NavBar.types";
import { filterItemsByUserPermissions } from "./nav-bar.utils";
import { useCurrentUserQuery } from "@libs/api/gateways/env/env-gateway.hooks";

export const navBarActionButtonStyles = (
  theme: ITheme
): Partial<IButtonStyles> => {
  const style: IStyle = { color: theme.palette.white };
  return {
    icon: style,
    menuIcon: style,
    iconHovered: style,
    iconPressed: style
  };
};

export const NavBar = () => {
  const theme = useTheme();
  const { data: user } = useCurrentUserQuery();
  const userPermissions = user?.impersonatingRole
    ? user.impersonatingRole.permissions
    : user?.permissions || [];

  const navigate = useNavigate();
  const handleMenuItemClick = (item: ItemData) => {
    navigate(item.to);
  };

  const filteredItems: IButtonProps[] = filterItemsByUserPermissions(
    userPermissions,
    [
      {
        text: "Tenants",
        iconProps: {
          iconName: "Package"
        },
        onClick: () =>
          handleMenuItemClick({
            to: "/tenants"
          })
      },
      {
        text: "Users",
        iconProps: {
          iconName: "People"
        },
        onClick: () =>
          handleMenuItemClick({
            to: "/users"
          })
      },
      {
        text: "Systems",
        iconProps: {
          iconName: "Settings"
        },
        menuProps: {
          items: [
            {
              key: "system-environment",
              text: "Environment",
              onClick: () => handleMenuItemClick({ to: "/system/env" })
            },
            {
              key: "system-platform",
              text: "Platform",
              onClick: () => handleMenuItemClick({ to: "/system/plt" })
            },
            {
              key: "system-pros",
              text: "Premier Online",
              onClick: () => handleMenuItemClick({ to: "/system/pros" })
            },
            {
              key: "best-health",
              text: "Best Health",
              onClick: () => handleMenuItemClick({ to: "/system/bh" })
            },
            {
              key: "omni",
              text: "Omni",
              onClick: () => handleMenuItemClick({ to: "/system/omni" })
            },
            {
              text: "BP Mobile",
              onClick: () => handleMenuItemClick({ to: "/system/bp-mobile" }),
              key: "bp-mobile"
            }
          ]
        }
      },
      {
        text: "Field Devices",
        iconProps: {
          iconName: "PC1"
        },
        onClick: () => handleMenuItemClick({ to: "/field-mgmt" })
      },
      {
        text: "Customers",
        iconProps: {
          iconName: "Package"
        },
        onClick: () => handleMenuItemClick({ to: "/cam" })
      }
    ]
  );

  return (
    <Header
      logoProps={{
        text: <EnvName />
      }}
      userBox={<UserProfile />}
      hoverEffectItems
    >
      {!!filteredItems.length &&
        filteredItems.map(i => (
          <ActionButton
            {...i}
            key={i.text?.split(" ").join()}
            styles={navBarActionButtonStyles(theme)}
          />
        ))}
    </Header>
  );
};
