import { config } from "@libs/config/config";

import { observer } from "mobx-react";

import { useMsal } from "@azure/msal-react";
import {
  ActionButton,
  IContextualMenuItem,
  IPersonaProps,
  MessageBar,
  MessageBarType,
  Persona,
  PersonaSize
} from "@bps/fluent-ui";
import {
  useCurrentUserImpersonation,
  useCurrentUserQuery,
  useEnvAllRoles
} from "@libs/api/gateways/env/env-gateway.hooks";

interface Props {
  personaProps?: IPersonaProps;
}

export const UserProfile = observer(({ personaProps }: Props) => {
  const { instance: msal } = useMsal();

  const handleLogout = async () => {
    await msal.logoutRedirect({
      account: msal.getActiveAccount(),
      postLogoutRedirectUri: "/"
    });
  };

  const {
    data: allRoles,
    error: allRolesError,
    isLoading: allRolesIsLoading
  } = useEnvAllRoles();

  const { data: currentUser } = useCurrentUserQuery();
  const activeImpersonation = currentUser?.impersonatingRole !== undefined;
  const userImpersonation = useCurrentUserImpersonation();

  const handleLogoutClicked = () => {
    handleLogout().then(() => {});
  };

  const menuItems: IContextualMenuItem[] = [
    {
      key: "logout",
      iconProps: { iconName: "SignOut" },
      text: "Logout",
      onClick: handleLogoutClicked
    },
    {
      key: "role-impersonate-on",
      text: "Impersonate Role",
      iconProps: { iconName: "Family" },
      disabled: allRolesIsLoading,
      onRenderContent: (props, defaultRenders) => {
        if (props && defaultRenders) {
          if (allRolesError) {
            return (
              <MessageBar messageBarType={MessageBarType.error}>
                {allRolesError.message}
              </MessageBar>
            );
          }
          return (
            <>
              {defaultRenders.renderItemIcon(props)}{" "}
              {defaultRenders.renderItemName(props)}
            </>
          );
        }
        return null;
      },
      data: {
        permissions: [],
        permissionsOperator: "or"
      },
      subMenuProps: {
        items:
          allRoles
            ?.filter(r => r.code !== "ADMIN")
            .map(r => ({ key: `role-${r.code}`, text: `${r.code}` })) || [],
        onItemClick: (ev, item) => {
          // Update the current user impersonatingRole
          userImpersonation(item?.text);
        }
      }
    },
    {
      key: "role-impersonate-off",
      text: "Cancel Role Impersonation",
      iconProps: { iconName: "AutoEnhanceOn" },
      disabled: !activeImpersonation,
      onClick: () => {
        // Update the current user impersonatingRole
        userImpersonation(undefined);
      }
    }
  ];

  return (
    <ActionButton
      menuProps={{
        shouldFocusOnMount: true,
        items: menuItems.filter(m =>
          config.env === "prod" ? m.key === "logout" : true
        )
      }}
    >
      <Persona
        text={currentUser?.impersonatingRole?.role || ""}
        imageInitials=""
        size={PersonaSize.size24}
        {...personaProps}
      />
    </ActionButton>
  );
});
