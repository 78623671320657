import { Notifications } from "@components/Notifications";
import { ComponentType, PropsWithChildren } from "react";

import { Stack, useTheme } from "@bps/fluent-ui";

import { NavBar } from "./nav-bar/NavBar";

export interface MainLayoutProps {
  toolbar?: ComponentType;
}

export const MainLayout = (props: PropsWithChildren<MainLayoutProps>) => {
  const { semanticColors } = useTheme();
  return (
    <Stack
      verticalFill
      styles={{ root: { backgroundColor: semanticColors.disabledBackground } }}
    >
      <NavBar />
      <Notifications />

      <Stack
        as="main"
        styles={{
          root: { overflow: "hidden", margin: 16, position: "relative" }
        }}
        grow
      >
        {props.children}
      </Stack>
    </Stack>
  );
};
