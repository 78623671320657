import { Permissions } from "@libs/permissions/permissions.enum";
import { ItemData } from "./NavBar.types";
import { IContextualMenuItem, IButtonProps } from "@bps/fluent-ui";
import { verifyHasPermissions } from "@libs/permissions/permissions.utils";

export const filterItemsByUserPermissions = (
  userPermissions: Permissions[],
  items: IButtonProps[]
) => {
  return items.reduce((acc, item) => {
    const itemData = item.data as ItemData;

    if (
      itemData &&
      itemData.permissions &&
      !verifyHasPermissions(
        userPermissions,
        itemData.permissions,
        itemData.permissionsOperator
      )
    ) {
      return acc;
    }

    const itemResult = { ...item };

    if (itemResult.menuProps) {
      itemResult.menuProps.items = filterMenuItemsByUserPermissions(
        userPermissions,
        itemResult.menuProps.items
      );

      // Don't add the menu item to the available items if all the submenu items are inaccessible, unless the parent
      // item has it's on 'to' link.
      if (!(itemResult.menuProps.items.length || itemData?.to)) {
        return acc;
      }
    }

    return [...acc, itemResult];
  }, [] as IButtonProps[]);
};

export const filterMenuItemsByUserPermissions = (
  userPermissions: Permissions[],
  items: IContextualMenuItem[]
) => {
  return items.reduce((acc, item) => {
    if (item.data?.permissions) {
      if (
        !verifyHasPermissions(
          userPermissions,
          item.data.permissions,
          item.data.permissionsOperator
        )
      ) {
        return acc;
      }
    }
    return [...acc, item];
  }, [] as IContextualMenuItem[]);
};
